import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import allocationSetting, { AllocationSetting } from '@/shared/model/allocationSetting';
import allocationBrandsImportSetting, {
  AllocationBrandsImportSetting,
} from '@/shared/model/allocationBrandsImportSetting';
import { OdataItems } from '@/shared/model/OdataItems';
import { AllocationBrand } from '@/shared/model/allocationBrand';
import EditBrandsPermittedDialog from './edit-brands-permitted-dialog/edit-brands-permitted-dialog.vue';
import ImportTableView from '@/views/settings/company/account-assignment/import-table/import-table.vue';

const name = 'brands-view';
const logger = new Logger(name);
const allocationSettingModule = namespace('allocationSetting');
const allocationBrandsImportSettingModule = namespace('allocationBrandsImportSetting');
const allocationBrandModule = namespace('allocationBrand');
const organizationModule = namespace('organization');

@Component({ name: name, components: { EditBrandsPermittedDialog, ImportTableView } })
export default class BrandsView extends Vue {
  @allocationSettingModule.Action('updateAllocationSetting')
  private updateAllocationSetting!: any;
  @allocationSettingModule.Action('getAllocationSettingCurrent')
  private getAllocationSetting!: any;
  @allocationSettingModule.Getter('getAllocationSetting')
  private allocationSetting!: AllocationSetting;
  @allocationBrandsImportSettingModule.Action('updateChangeClearBeforeImport')
  private updateChangeClearBeforeImport!: any;
  @allocationBrandsImportSettingModule.Action('getAllocationBrandsImportSetting')
  private getAllocationBrandsImportSetting!: any;
  @allocationBrandsImportSettingModule.Getter('getAllocationBrandsImportSetting')
  private allocationBrandsImportSetting!: AllocationBrandsImportSetting;
  @allocationBrandsImportSettingModule.Getter('getInProgressUpdateAllocationBrandsImportSetting')
  private inProgressUpdateAllocationBrandsImportSetting!: boolean;
  @allocationBrandsImportSettingModule.Getter('getAllocationBrandsImportSettingsIsLoading')
  private allocationBrandsImportSettingsIsLoading!: boolean;
  @allocationBrandModule.Action('getAllocationBrands')
  private getAllocationBrands!: any;
  @allocationBrandModule.Getter('getAllocationBrands')
  private allocationBrands!: OdataItems<AllocationBrand>;
  @allocationBrandModule.Getter('getIsImporting')
  private isImporting!: boolean;
  @allocationBrandModule.Action('uploadAllocationBrand')
  private uploadAllocationBrand!: any;
  @organizationModule.Getter('getOrganization')
  private organization!: any;
  @allocationBrandModule.Action('getAllAllocationBrands')
  private getAllAllocationBrands!: () => Promise<any>;

  brandDigitses = [
    { id: 1, text: '1 Stellig' },
    { id: 2, text: '2 Stellig' },
  ];
  editBrandsAllowed() {
    this.openDialog(this.allocationSetting);
  }
  updateOfficesAllowed(item: AllocationSetting) {
    this.updateAllocationSetting(item);
  }
  uploadFinished() {
    this.getAllAllocationBrands();
  }
  //#region Dialog logic
  dialogAddEdit = {
    show: false,
    model: {},
  };

  async dialogAddEditOnClose(folder: any) {
    this.dialogAddEdit.show = false;
  }

  async dialogAddEditOnUpdate(item: any) {
    this.updateOfficesAllowed(item);
    this.dialogAddEdit.show = false;
  }

  private async openDialog(status: any) {
    this.dialogAddEdit.model = { ...status };
    this.dialogAddEdit.show = true;
  }
  //#endregion

  onChangeBrandDigits() {
    this.updateOfficesAllowed(this.allocationSetting);
  }
  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('brand_number'), value: 'brandNumber', sortable: false },
      { text: this.$t('name'), value: 'name', sortable: false },
    ];
    return headers;
  }

  async mounted() {}
}
