import { render, staticRenderFns } from "./brands.html?vue&type=template&id=498f350e&scoped=true&external"
import script from "./brands.ts?vue&type=script&lang=ts&external"
export * from "./brands.ts?vue&type=script&lang=ts&external"
import style0 from "./brands.scss?vue&type=style&index=0&id=498f350e&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498f350e",
  null
  
)

/* custom blocks */
import block0 from "./brands.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Faccount-assignment%2Fbrands%2Fbrands.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports