import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';

const name = 'edit-offices-permitted-dialog';
const logger = new Logger(name);

@Component({
  name: name,
  components: {},
})
export default class EditOfficesPermittedDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => {} })
  private value!: any;

  private onClose() {
    this.$emit('click:close', this.value);
  }

  private async onConfirm() {
    this.$emit('click:update', this.value);
  }
}
